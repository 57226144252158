/**
 * Modifies the muiTheme with the options from customTheme
 * 
 * @param {object} muiTheme 
 * @param {object} customTheme 
 */

export const modifyMui = (muiTheme, customTheme) => {
    const t = customTheme.ThemeOptions;
    muiTheme.customTheme = t;

    let fonts = {
        headerFont : '',
        subHeaderFont : '',
        bodyFont : '',
    };

    // Fonts
    if(t.fontSet === 'modern') {
        fonts.headerFont = 'IBM Plex Sans, sans-serif';
        fonts.subHeaderFont = 'IBM Plex Sans, sans-serif';
        fonts.bodyFont = 'Open Sans, sans-serif';
    } else if(t.fontSet === 'retro') {
        fonts.headerFont = 'MuseoModerno, sans-serif';
        fonts.subHeaderFont = 'MuseoModerno, sans-serif';
        fonts.bodyFont = 'Roboto, sans-serif';
    } else if(t.fontSet === 'heritage') {
        fonts.headerFont = 'Merriweather, sans-serif';
        fonts.subHeaderFont = 'Merriweather, sans-serif';
        fonts.bodyFont = 'Merriweather, sans-serif';
    } else if(t.fontSet === 'friendly') {
        fonts.headerFont = 'Quicksand, sans-serif';
        fonts.subHeaderFont = 'Quicksand, sans-serif';
        fonts.bodyFont = 'Rubik, sans-serif';
    } else if(t.fontSet === 'motorsharp') {
        fonts.headerFont = 'Oswald, sans-serif';
        fonts.subHeaderFont = 'Oswald, sans-serif';
        fonts.bodyFont = 'Raleway, sans-serif';
    } else if(t.fontSet === 'geomotor') {
        fonts.headerFont = 'Poppins, sans-serif';
        fonts.subHeaderFont = 'Poppins, sans-serif';
        fonts.bodyFont = 'Lato, sans-serif';
    } else {
        fonts.headerFont = t.fonts.headerFont + ', sans-serif';
        fonts.subHeaderFont = t.fonts.subHeaderFont + ', sans-serif';
        fonts.bodyFont = t.fonts.bodyFont + ', sans-serif';
    }

    const bodyFont = fonts.bodyFont;
    muiTheme.bodyFont = bodyFont;
    const headerFont = fonts.headerFont;
    muiTheme.headerFont = headerFont;
    const buttonFont = fonts.subHeaderFont;
    muiTheme.buttonFont = buttonFont;
    
    muiTheme.overrides.MuiCssBaseline = {
        '@global': {
          'body': {
            fontFamily: bodyFont,
          },
          'h1, h2, h3, h4, h5, h6': {
            fontFamily: headerFont,
          }  
        },
    };
    muiTheme.typography = {
        fontFamily: bodyFont,
        useNextVariants: true,
    };

    // Colours
    muiTheme.palette = {
        primary: {
            "main": t.primary.primaryColour,
            "light": shadeBlend(0.1, t.primary.primaryColour),
            "dark": shadeBlend(-0.1, t.primary.primaryColour),
            "contrastText": t.primary.onPrimary,
            "highlight": t.primary.primaryHighlight,
        },
        secondary: {
            "main": t.secondary.secondaryColour,
            "light": shadeBlend(0.1, t.secondary.secondaryColour),
            "dark": shadeBlend(-0.1, t.secondary.secondaryColour),
            "contrastText": t.secondary.onSecondary,
            "highlight": t.secondary.secondaryHighlight,
        },
        tertiary: {
            "main": t.tertiary.tertiaryColour,
            "light": shadeBlend(0.1, t.tertiary.tertiaryColour),
            "dark": shadeBlend(-0.1, t.tertiary.tertiaryColour),
            "contrastText": t.tertiary.onTertiary,
            "highlight": t.tertiary.tertiaryHighlight,
        },
        accent: {
            "main": t.accent.accentColour,
            "light": shadeBlend(0.1, t.accent.accentColour),
            "dark": shadeBlend(-0.1, t.accent.accentColour),
            "contrastText": t.accent.onAccent,
            "highlight": t.accent.accentHighlight,
        },
        text: {
            primary: t.generalColours.black,
            secondary: t.generalColours.black
        },
        background: {
            "paper": t.generalColours.white,
            "default": t.generalColours.bodyBackground,
            "primary": t.generalColours.bodyBackground,
            "secondary": t.generalColours.bodyBackground
        }
    };

    muiTheme.common = {
        "black": t.generalColours.black,
        "white": t.generalColours.white,
    };

    muiTheme.border = {
        "radius" : t.borders.radius,
        "thickness" : t.borders.thickness,
    }

    if(t.shadow.color === 'custom') {
        muiTheme.shadow = t.shadow.depth + ' var(--' + t.shadow.customColour + ')';
    } else {
        muiTheme.shadow = t.shadow.depth + ' ' + t.shadow.colour;
    }

    return muiTheme;
}

function shadeBlend(p,c0,c1) {
    var n=p<0?p*-1:p,u=Math.round,w=parseInt;
    if(c0.length>7){
        var f=c0.split(","),t=(c1?c1:p<0?"rgb(0,0,0)":"rgb(255,255,255)").split(","),R=w(f[0].slice(4)),G=w(f[1]),B=w(f[2]); // eslint-disable-line
        return "rgb("+(u((w(t[0].slice(4))-R)*n)+R)+","+(u((w(t[1])-G)*n)+G)+","+(u((w(t[2])-B)*n)+B)+")" // eslint-disable-line
    }else{
        var f=w(c0.slice(1),16),t=w((c1?c1:p<0?"#000000":"#FFFFFF").slice(1),16),R1=f>>16,G1=f>>8&0x00FF,B1=f&0x0000FF; // eslint-disable-line
        return "#"+(0x1000000+(u(((t>>16)-R1)*n)+R1)*0x10000+(u(((t>>8&0x00FF)-G1)*n)+G1)*0x100+(u(((t&0x0000FF)-B1)*n)+B1)).toString(16).slice(1) // eslint-disable-line
    }
}