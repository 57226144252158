import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import themeData from "./muiTheme.json";
import themeOptions from "../../../.brew-cache/theme.json";
import {modifyMui} from "../modifyMui";

const themeName = 'Brew Core Theme';
const modifiedTheme = modifyMui(themeData, themeOptions);
let theme = createMuiTheme({ ...modifiedTheme, themeName });
theme = responsiveFontSizes(theme, { factor: 3 });

// Custom theme styles here or import them from wherever if they need to be broken

export default theme;